
import React from 'react';
import {NavLink} from "react-router-dom";

export default function Footer() {
  return (
    <footer className="site-footer">
      <span className="copyright">&copy; 2023 Russ Kirby. Built in React.</span>
      <nav className="social-nav">
        <ul className="social-nav__list">
          <li className="social-nav__item">
            <a href="https://www.linkedin.com/in/russjkirby" rel="noopener nofollow" className="social-nav__link social-nav__link--linkedin"></a>
          </li>
          <li className="social-nav__item">
            <a href="https://github.com/tw1np1ne" target="_blank" rel="noopener nofollow" className="social-nav__link social-nav__link--github"></a>
          </li>
          <li className="social-nav__item">
            <NavLink className="social-nav__link social-nav__link--contact" to="/contact"></NavLink>
          </li>
        </ul>
      </nav>
    </footer>
  );
}
