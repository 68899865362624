export default {
	"success": true,
	"data": {
			"demos": [
					// {
					// 		"id": "1",
					// 		"name": "React.js Meme Generator",
					// 		"desc": "I put this little meme generator together a while back when I was learning React. It was a nice project to get to grips with connecting to API's, manipulating state, array mapping and conditional rendering. I also wanted a way to be able to download the image after creating it so ended up utlising the HTML 2 Canvas plugin to do the heavy lifting. After a bit of back and forth with CORS issues I managed to get the download fuctionality working and the project was complete.",
					// 		"bg": "https://russjkirby.com/images/demos/meme.webp",
					// 		"buttonText": "View Project",
					// 		"url": "/sandbox/meme-generator/"
					// },
					{
							"id": "2",
							"name": "Vue.js Pin Board Prototype",
							"desc": "I built this prototype whilst I had some down time at work and was looking to upskill a bit more in Vue.js. The idea behind this was to build a platform where employees could ask for help from other team members to resolve blockers or issues that were slowing down their day to day tasks without them having to send blanket emails that ultimately get ignored. After building the messaging architecture, using Google's Firebase to cater for the DB and endpoints, I expanded on my original MVP by adding search and filtering functionality.",
							"bg": "https://russjkirby.com/images/demos/pin-board.webp",
							"buttonText": "View Project",
							"url": "/sandbox/pin-board/"
					}
			]
		}
}