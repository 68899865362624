
import React from 'react';

import Demo from "./Demo"
import demoData from "../data/demos"


export default function Play() {

  const demoElements = demoData.data.demos.map(project => {
    return (
      <Demo
        key={project.id}
        id={project.id}
        bg={project.bg}
        name={project.name}
        desc={project.desc}
        buttonText={project.buttonText}
				url={project.url}
      />
    )
  })

  return (
    <div className="page page--play">

      <section className="demo-opener">
        <div className="intro intro--tr">
          <h1 className="intro__title intro__title--large">play.</h1>
          <p>Occasionally, when I manage to carve out some free time, I enjoy tinkering with small-scale projects and prototypes. Not only does this help keep my skills sharp but I get to try out things I don't get to in my day job. Below are a couple of examples...</p>
        </div>
      </section>
      
      <section className="demos">
        {demoElements}
      </section>
    </div>
  );
}

