export default {
	"success": true,
	"data": {
			"projects": [
					{
							"id": "1",
							"class": "bt",
							"name": "BT Marketing Hub",
							"meta": "2021 at Jellyfish",
							"desc": "The BT Digital Marketing is built using Angular for the front end with a Laravel back end. The application uses Storybook for efficient component management served through a custom node package. My contribution primarily centered around the development of the landing page builder part of the project. Within this role, I facilitated the creation of a dynamic environment where customers are able to construct their own landing pages using a collection of customisable and interchangeable component blocks, giving users a seamless and intuitive experience.",
							"bg": "https://russjkirby.com/images/projects/bt.webp",
							"screens": null,
							"buttonText": "Visit Hub",
							"url": "https://marketing.business.bt.com/login/welcome",
							"webby": false
					},
					{
							"id": "2",
							"class": "simmons",
							"name": "Simmons & Simmons",
							"meta": "2020 at Jellyfish",
							"desc": "Simmons & Simmons, a prominent law firm, required an extensive 'toolkit' of Vue.js components to be developed for their in-house team. These components were intended to seamlessly integrate into their backend system, allowing them to create custom page layouts.  This project required extensive collaboration with their backend team, entailing numerous code demos and comprehensive walkthroughs. The project was a big success and even was nominated for the <a href='https://www.webbyawards.com/crafted-with-code/simmons-simmons/' target='_blank' rel='noreferrer noopener'>2020 Webby Awards</a> where it was the runner up for best website in the Law sector.",
							"bg": "https://russjkirby.com/images/projects/simmons.webp",
							"screens": null,
							"buttonText": "Visit Site",
							"url": "https://www.simmons-simmons.com/",
							"webby": true
					},
					{
							"id": "3",
							"class": "aviva",
							"name": "Aviva Investors",
							"meta": "2018 - 2019 at Jellyfish",
							"desc": "Whilst at Jellyfish, I assumed responsibility for a series of projects on behalf of Aviva Investors. The majority of these websites entailed close collaboration with their in-house development team, focusing on the creation of new templates utilising their existing component modules. Additionally, I forged a close partnership with their Qlik Sense developer to seamlessly incorporate interactive dashboards into specific projects, enhancing the overall value and functionality of the various products.",
							"bg": "https://russjkirby.com/images/projects/aviva.webp",
							"screens": null,
							"buttonText": "Visit Site",
							"url": "https://www.avivainvestors.com/en-gb/",
							"webby": false
					},
					{
							"id": "4",
							"class": "universal",
							"name": "Universal Music Publishing Group",
							"meta": "2016 - 2018 at Lightmaker",
							"desc": "Working at Lightmaker gave me the opportunity to work on two pivotal projects in collaboration with Universal Music Publishing Group (UMPG). The initial venture encompassed the creation of a Royalty Window Portal, a comprehensive platform enabling artists to oversee and manage all aspects of their royalties. The second project was the redevelopement of front-end templates for UMPG's song catalogue website. Both initiatives were crafted using the latest trends in HTML, CSS and JS, then handed over to UMPG's in-house development team, who adeptly integrated them into their Angular backend infrastructure.",
							"bg": "https://russjkirby.com/images/projects/universal.webp",
							"screens": null,
							"buttonText": "Visit Site",
							"url": "https://www.umusicpub.com/uk/",
							"webby": false
					},
					{
							"id": "5",
							"class": "rooney",
							"name": "Wayne Rooney",
							"meta": "2016 at Lightmaker",
							"desc": "Wayne Rooneys's personal website was my first venture into Angular.js, and it remains one of my personal favourites. The project demanded a slick and polished website interface, integrating copious social feeds, match statistics, and media elements. While it posed challenges at the outset, the final product exhibited both exceptional aesthetics and flawless functionality. Its warm reception within the sporting community further underscored the project's success.",
							"bg": "https://russjkirby.com/images/projects/rooney.webp",
							"screens": [
								{
										"id": "1",
										"name": "Wayne 1",
										"src": "https://russjkirby.com/images/projects/wayne-1.webp"
								},
								{
									"id": "2",
									"name": "Wayne 2",
									"src": "https://russjkirby.com/images/projects/wayne-2.webp"
								},
								{
									"id": "3",
									"name": "Wayne 3",
									"src": "https://russjkirby.com/images/projects/wayne-3.webp"
								}
							],
							"buttonText": "Screenshots",
							"url": null,
							"webby": false
					},
					{
							"id": "6",
							"class": "ea",
							"name": "Electronic Arts",
							"meta": "2010 - 2016 at Lightmaker",
							"desc": "Electronic Arts were one of the first clients I was given to look after when starting at Lightmaker. I had the distinct privilege of developing the European iterations of renowned titles including Dragon Age 2, Mass Effect 2 and 3, The Sims and Star Wars: The Old Republic sites. The platforms all had to be multilingual and needed to showcase exceptionally immersive graphics and media content. Regrettably, due to the relatively short shelf life of game-related sites this means none of the original versions are accessible online anymore.",
							"bg": "https://russjkirby.com/images/projects/electronic-arts.webp",
							"screens": null,
							"buttonText": "Screenshots",
							"url": null,
							"webby": false
					}
			]
	}
}