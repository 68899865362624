
import React from 'react';
import {NavLink} from "react-router-dom";


export default function Header() {
  const [menuOpen, setMenuOpen] = React.useState(false)

  function toggleMenu() {
    setMenuOpen(prevState => !prevState)
  }

  return (
    <header className={`site-header ${menuOpen ? "site-header--nav-open" : ""}`} id="siteHeader">
      <nav className="primary-nav" id="primaryNav">
          <ul className="primary-nav__list">
            <li className="primary-nav__list-item">
              <NavLink to="/" onClick={toggleMenu} className="primary-nav__list-link">Home.</NavLink>
            </li>
            <li className="primary-nav__list-item">
              <NavLink to="/work" onClick={toggleMenu} className="primary-nav__list-link">Work.</NavLink>
            </li>
            <li className="primary-nav__list-item">
              <NavLink to="/play" onClick={toggleMenu} className="primary-nav__list-link">Play.</NavLink>
            </li>
            <li className="primary-nav__list-item">
              <NavLink to="/contact" onClick={toggleMenu} className="primary-nav__list-link">Contact.</NavLink>
            </li>
        </ul>
      </nav>
      <button className="nav-btn" onClick={toggleMenu}><span className="nav-btn__lines"></span></button>
    </header>
  );
}
