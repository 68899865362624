import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';



export default function Contact() {
  const [response, setResponse] = React.useState("default")
  const [loading, setLoading] = React.useState(false)
  const form = useRef();

  function formSubmit() {
    setLoading(true)
  }

  const SendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mmh2tac', 'template_qhqvv87', form.current, 'Fp7ooYqtixmso1mIS')
      .then((result) => {
        setResponse("success")
        setLoading(false)
      }, (error) => {
        setResponse("error")
        setLoading(false)
      });
  };

  return (
    <div className="page page--contact">
      <div className="intro intro--flow">
        <h1 className="intro__title">lets<br />chat.</h1>
        <p>If you'd like to reach out then please don't hesitate. You can either fill in the form or email me directly at <strong>russjkirby</strong>@<strong>gmail.com</strong></p>
      </div>
      <div className="form">
        <div className="form__content">
          <h2>Drop me a line.</h2>
          <p>Just fill in the required fields below and I will do my best to get back to you as soon as I can.</p>
          <form ref={form} onSubmit={SendEmail}>
            <fieldset>
              <label>
                Name*
                <input type="text" name="name" required />
              </label>
              <label>
                Email*
                <input type="email" name="email" required />
              </label>
              <label>
                Phone number
                <input type="phone" name="phone" />
              </label>
            </fieldset>
            <label>
              Message*
              <textarea name="message" required></textarea>
            </label>
            <div className={"form__response " + (response === 'success' ? ' form__response--success' : '')}>
              <span>Thanks! I'll get back to you as soon as I can.</span>
            </div>
            <div className={"form__response " + (response === 'error' ? ' form__response--error' : '')}>
              <span>Whoops! Look like something went wrong. Pleaase try again later.</span>
            </div>
            <button className={"button button--full " + (loading ? ' button--loading' : '')} type="submit" onClick={formSubmit}>
              Send
              {loading ? (
                <span className="button__spinner"><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="m304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm-368 48c0-26.51-21.49-48-48-48s-48 21.49-48 48 21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zm-294.156-294.156c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"/></svg></span>
              ): null}
                
              
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
