import React from "react"

import { Waypoint } from 'react-waypoint';

import webbyImg from '../assets/images/projects/webby-nominee.png';

export default function Project(props) {
	const webby = props.webby;

	const [visible, setVisible] = React.useState(false)
	const [overlay, setOverlay] = React.useState(false)
	const [count, setCount] = React.useState(0)

  function enter() {
		setVisible(prevState => true)
	}

	function leave() {
		setVisible(prevState => false)
	}

	function prev() {
		setCount(count - 1)
		console.log(count);
	}

	function next() {
		setCount(count + 1);
		console.log(count);
	}

	function screens() {
		setOverlay(prevState => !prevState)
		props.freezePage()
	}


	return (
		<Waypoint bottomOffset="90%" onEnter={enter}  onLeave={leave}>
			<article className={"project project--" + props.class + (visible ? ' project--active' : '')}>
				<div className="project__bg" style={{backgroundImage: `url(${props.bg})` }}></div>
				<div className="project__text">
					{ webby ? <a href="https://www.webbyawards.com/crafted-with-code/simmons-simmons/" target="_blank" rel="norefferer noopener"><img className="webby-badge" src={webbyImg} alt="Webby nominated" /></a> : '' }
					<h3>{props.name}</h3>
					<span className="project__meta">{props.meta}</span>
					<p dangerouslySetInnerHTML={{ __html: props.desc }}></p>
					{props.screens ? (
						<button onClick={screens} className="button">{props.buttonText}</button>
					) : ''}
					{props.url ? (
						<a href={props.url} target="_blank" rel="noreferrer" className="button">{props.buttonText}</a>
					) : ''}
				</div>


				{props.screens ? (
					<div className={"modal " + (overlay ? 'modal--active' : '')}>
						<div className="overlay" onClick={screens} ></div>
						<div className="modal-content">
							<div className="browser">
								<button className="browser__close" onClick={screens}></button>
								<div className="browser__top-bar">
									<span className="browser__controls"></span>
									<div className="browser__url"></div>
								</div>
								<div className="browser__screen">
									<img src={props.screens[count].src} alt={props.screens[count].name} /> 
								</div>
								<button onClick={prev} className={"pagination pagination--prev" + (count < 1 ? ' pagination--disabled' : '')}></button>
								<button onClick={next} className={"pagination pagination--next" + (count > 1 ? ' pagination--disabled' : '')}></button>
							</div>	
							
						</div>
					</div>
				) : (
					null
				)}


				
			</article>
		</Waypoint>

	)
}
	
