import React from "react"

import { Waypoint } from 'react-waypoint';

export default function Demo(props) {

	const [visible, setVisible] = React.useState(false)
	
	function enter() {
		setVisible(prevState => true)
	}

	function leave() {
		setVisible(prevState => false)
	}

	return (
		<Waypoint bottomOffset="30%" onEnter={enter}  onLeave={leave}>
			<article className={"demo" +  (visible ? ' demo--active' : '')}>
				<div className="demo__text">
					<div className="demo__text-content">
						<h3>{props.name}</h3>
						<p>{props.desc}</p>
						<a href={props.url} target="_blank" rel="noreferrer" className="button button--outline">{props.buttonText}</a>
					</div>
				</div>
				<div className="demo__media">
					<a href={props.url} target="_blank" rel="noreferrer">
						<div className="media__phone">
							<img src={props.bg} alt={props.name} />
						</div>
					</a>
				</div>
			</article>
		</Waypoint>

	)
}
	
