
import React from 'react';
import { Waypoint } from 'react-waypoint';

import '../assets/js/app';
import Project from "./Project"
import Client from "./Client"
import projectData from "../data/projects"
import clientData from "../data/clients"


export default function Work(props) {

  const projectElements = projectData.data.projects.map(project => {
    return (
      <Project
        key={project.id}
        id={project.id}
        class={project.class}
        bg={project.bg}
        name={project.name}
        meta={project.meta}
        desc={project.desc}
        screens={project.screens}
        buttonText={project.buttonText}
				url={project.url}
        webby={project.webby}
        freezePage={props.freezePage}
      />
    )
  })

  const clientElements = clientData.data.clients.map(client => {
    return (
      <Client
        key={client.id}
        name={client.name}
        logo={client.logo}
      />
    )
  })


  const [grid, setGrid] = React.useState(false)
  const [logos, setLogos] = React.useState(false)

  function enter() {
		setGrid(prevState => true)
    setTimeout(function() {
      setLogos(prevState => true)
    }, 600);
	}

  return (
    <div className="page page--work">

      <section className="opener">
        <div className="intro intro--tr">
          <h1 className="intro__title intro__title--large">work.</h1>
          <p>Throughout my career I've had the privilege of collaborating with some remarkable clients, leading to the fruition of some exceptional projects. Here are a few of my personal favourites...</p>
          {/* <div className="scroll-down"></div> */}
        </div>
      </section>
      <section className="projects">
        {projectElements}
      </section>


      <Waypoint onEnter={enter}>
        <section className={"clients " + (grid ? ' clients--active' : "")} id="clients">
          <ul className={"client-list " + (logos ? ' client-list--active' : "")}>
            <li className="client-list__item client-list__item--header">
              <p>I have also enjoyed working with...</p>
            </li>
            {clientElements}
          </ul>
        </section>
      </Waypoint>

      
    </div>
  );
}

