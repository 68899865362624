import React, {createRef} from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';


import Header from'./components/Header';
import Footer from'./components/Footer';
import Home from'./components/Home';
import Play from'./components/Play';
import Work from'./components/Work';
import Contact from'./components/Contact';

const AnimatedRoutes = (props) => {
  const location = useLocation();
  const wrapper = createRef();
  return (
    <div ref={wrapper}>
      <TransitionGroup>
        <CSSTransition key={location.key} timeout={500} classNames="fade">
          <div className="page-wrapper">
            <Routes location={location}>
              <Route index element={<Home />} />
              <Route path="/work" element={<Work freezePage={props.freezePage} />} />
              <Route path="/play" element={<Play/>} />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
    
  );
};

function App() {

  const [freeze, setFreeze] = React.useState(false)

  function freezePage() {
    setFreeze(prevState => !prevState)
    if(!freeze) {
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('body').style.height = '100dvh';
    } else {
      document.querySelector('body').style.overflow = 'visible';
      document.querySelector('body').style.height = 'auto';
    }
  }

  

  return (
    <div className={"App" + (freeze ? ' disable-controls' : "")}>
       <div className="frame">
        <BrowserRouter>
          <Header />
          <AnimatedRoutes freezePage={freezePage} />
          <Footer />
        </BrowserRouter>
       </div>
    </div>
  );
}

export default App;
