import React from 'react';
import {NavLink} from "react-router-dom";

// Assets
import sky from '../assets/images/sky.webp';
import scene from '../assets/images/scene.webp';

export default function Home() {
  return (
    <div className="page page--home">
      <div className="page__bg">
        <div className="bg bg--sky" style={{backgroundImage: "url(" + sky + ")"}}></div>
        <div className="bg bg--land" style={{backgroundImage: "url(" + scene + ")"}}></div>
      </div> 
      <div className="intro">
        <h1 className=" glitch" data-text="hi.">hi.</h1>
        <p className="intro__text">My name is Russ Kirby. For nearly 16 years, I've been honing my skills as a Front End Developer, crafting websites and apps for clients of all sizes, all over the world.<br />Please take a minute a look at some of my <NavLink to="/work">projects.</NavLink></p>
      </div>
    </div>
  );
}
